import '../App.css';
import React from 'react';
import '../App.css';

function Blog(){
    return(
    <div className='App'>
      <div className='banner'>
        <div className='hero-title'>
          BLOG
        </div>
      </div>
      <div className='hero-block'>
        <div className='hero-image'>
        </div>
        <div className='hero-blurb'>
          hello i am a computer science major at notre dame and this is my website and its test text thank u 
        </div>
      </div>
    </div>
  );
}

export default Blog;